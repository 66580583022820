import './App.css';
import ClientForm from './ClientForm';
import Success from './Success';
import Preface from './Preface';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Router className="App" style={{color: "#f5f5f5"}}>
        <Routes>
          <Route exact path="/" element={<Preface />} />
          <Route exact path="/form" element={<ClientForm />} />
          <Route exact path="/success" element={<Success />} />
        </Routes>
      </Router>
  );
}

export default App;
