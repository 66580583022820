import React from 'react';
import logo from './partners_logo.png';
import {
    Sheet,
    SheetContent,
    // SheetDescription,
    // SheetHeader,
    // SheetTitle,
    SheetTrigger,
  } from "./ui/sheet"
  import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

const Success = () => {

    return (
        <div className="bg-gray-100 App h-screen">

            <nav className="flex justify-between items-center bg-white p-4 border-b-2">
                <div className='w-fit'>
                <a href="https://www.partnerspersonnel.com/">
                    <img src={logo} alt="Company Logo" className="h-12 hidden md:block" />
                </a>
                <a href="https://www.partnerspersonnel.com/">
                    <img src="partnerP.png" alt="Company Logo" className="h-12 block md:hidden" />
                </a>
                </div>
                <div className="ml-2 flex items-center">
                <a href="https://jobs.partnerspersonnel.com/job-search?limit=25" className="no-underline mr-4 md:border rounded-2 p-1 px-3">
                    <div className="text-black hidden md:block">Looking for a job? Click here</div>
                    {/* <div className="text-black hidden md:block">¿En busca de un trabajo? Haga clic aquí</div> */}
                </a>
                <div className="grid grid-cols-2 gap-2 ml-2 md:ml-4">
          <Sheet key="right">
          <SheetTrigger><img src="Menu.png" alt="menu" /></SheetTrigger>
            <SheetContent side="right">

              <Tabs defaultValue="english" className="w-full mt-8">
                <TabsList>
                  <TabsTrigger value="english" className="focus:bg-slate-500 focus:text-white">English</TabsTrigger>
                  <TabsTrigger value="spanish" className="focus:bg-slate-500 focus:text-white">Español</TabsTrigger>
                </TabsList>
                <hr className="border-t border-gray-500" />
                <TabsContent value="english">  
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Home</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-work/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Find Work</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-people/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Staffing Solutions</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/announcements2/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Resources</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/about-us/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">About Us</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/locations-list/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Locations</div>
                    </a>
                  </div>
                </TabsContent>
                <TabsContent value="spanish">  
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Inicio</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-work/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Encontrar Trabajo</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-people/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Soluciones</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/announcements2/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Recursos</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/about-us/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Sobre Nosotros</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/locations-list/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Ubicaciones</div>
                    </a>
                  </div>
                </TabsContent>
              </Tabs>

              
            </SheetContent>
          </Sheet>
      </div>
                </div>
            </nav>
            <div className="flex flex-col justify-between  md:flex-row md:!px-24 md:!py-12">


            <div className="flex w-2/5 justify-center">
                <div className="hidden md:flex md:flex-col ml-4 md:ml-0 w-full md:w-[400px]">

                <div className="font-semibold text-slate-900 text-4xl leading-16 mt-16 mb-8 mr-12">
                    Finding employees to fill your shifts has never been easier.
                </div>
                <div className="text-slate-900 text-xl leading-16 mb-16 mr-12">
                    Fill out the form to get started with hassle-free staffing services today!
                </div>
                    <div className="flex items-center">
                        <img src="check.svg" alt="checkmark" className="p-2 mr-4 border border-gray-200 rounded-full bg-white" />
                        <div className="text-md">Local market expertise and quick response time</div>
                    </div>
                    <div className="flex items-center mt-4">
                        <img src="check.svg" alt="checkmark" className="p-2 mr-4 border border-gray-200 rounded-full bg-white" />
                        <div className="text-md">Skilled associates and competitive rates</div>
                    </div>
                    <div className="flex items-center mt-4">
                        <img src="check.svg" alt="checkmark" className="p-2 mr-4 border border-gray-200 rounded-full bg-white" />
                        <div className="text-md">Personalized and streamlined staffing solutions</div>
                    </div>

                    <hr className="border-t border-gray-500 mr-4" />

                    <div className="flex items-center justify-between mt-4 mr-16">
                        <img src="bestGreen.png" alt="bestTalent" />
                        <img src="bestBlue.png" alt="bestTalent" />
                        <img src="sia.png" alt="bestTalent" />
                    </div>
                </div>
            </div>

        <div className="flex flex-col p-6 w-full h-fit md:w-3/5 bg-white border rounded">
            <div className="flex items-center mb-4">
                    <img src="Ok.png" alt="check" className="mx-4" />
                <div className="text-lg">Submission success!</div>
            </div>

            <div className="flex flex-col border rounded p-2">
                <div className="text-lg mt-2 ml-2">Your local branch account executive will be in contact within the next hour.</div>
                {/* <div className="flex my-4">
                    <img src="User.png" alt="user" className="p-2 border rounded-full bg-gray-200" />
                    <div className="flex flex-col justify-between ml-4">
                        <div className="text-3xl font-bold">Placeholder Name</div>
                        <div className="text-md">email@partners.com  801-123-4567</div>
                    </div>
                </div> */}
            </div>

            <div className="self-end bg-slate-800 border rounded p-3 mt-4">
                <a href="https://www.partnerspersonnel.com/" className="text-white text-lg no-underline">
                    Back to Partnerspersonnel.com
                </a>
            </div>
        </div>
    </div>
            {/* <nav className="navbar custom-nav">
                <div className="custom-nav">
                    <a className="navbar-brand" href="https://www.partnerspersonnel.com/">
                    <img src={logo} alt="Company Logo" height="50" href="https://www.partnerspersonnel.com/"/>
                    </a>
                </div>
            </nav>
            <hr className="divider" />
            <div className="App-header">
                {formData && (
                <div className="item">
                <h1 style={{fontWeight:"600"}}>{formData.company}</h1>
                </div>
                )}
                <div className="item">
                    <h3>Thanks for submitting your information</h3>
                </div>
                <div className="item" style={{justifyContent:"center", display:"flex"}}>
                    <h5 className="item" style={{textAlign:"center", fontSize:"1rem", width:"60%", marginBottom:"50px"}}>Your account executive will be in contact soon to complete the following steps</h5>
                </div>
                <CustomCard title="Step 1 (completed)" info="Account setup initiated" color="#06ba63" />
                <CustomCard title="Step 2" info="Client onboarding initiated by account executive" color="#adadad"/>
                <CustomCard title="Step 3" info="Onboarding completed and approved to hire" color="#adadad"/>
                <a className="btn btn-primary col-md-4 item button" href="https://www.partnerspersonnel.com/" rel="noopener noreferrer">
                    Back to home
                </a>
            </div> */}
            
            
    </div>
    );
};

export default Success;