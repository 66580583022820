import React from 'react';
import logo from './partners_logo.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Sheet,
    SheetContent,
    // SheetDescription,
    // SheetHeader,
    // SheetTitle,
    SheetTrigger,
  } from "./ui/sheet"
  import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";

const SelectableCard = ({ cardId, selected, onClick, firstLine, secondLine, cardImage }) => {
    const isSelected = selected === cardId;
    return (
      <div 
        className={`p-4 border-2 rounded flex ${isSelected ? 'border-blue-800 bg-gray-100' : 'border-gray-300'} cursor-pointer`}
        onClick={() => onClick(cardId)}
      >
        <img src={cardImage} alt="placeholder" className="mr-4" />
        <div className="flex flex-col justify-center space-y-4">
            <div className="font-bold text-xl md:text-2xl">{firstLine}</div>
            <div className="text-md">{secondLine}</div>
        </div>
      </div>
    );
  };

const Preface = () => {

    const [selectedCard, setSelectedCard] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleCardClick = (cardId) => {
        setSelectedCard(cardId);
        setButtonDisabled(false);
    };




    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (selectedCard === 'company') {
          // Navigate to one page
          navigate('/form');
        } else {
          // Navigate to another page
          window.location.href = ('https://jobs.partnerspersonnel.com/job-search?limit=25');
        }
    };

    return (
        <div className="App bg-gray-100">
            <nav className="flex justify-between items-center bg-white p-4 border-b-2">
                <div className='w-fit'>
                <a href="https://www.partnerspersonnel.com/">
                    <img src={logo} alt="Company Logo" className="h-12 hidden md:block" />
                </a>
                <a href="https://www.partnerspersonnel.com/">
                    <img src="partnerP.png" alt="Company Logo" className="h-12 block md:hidden" />
                </a>
                </div>
                <div className="ml-2 flex items-center">
                
                <div className="grid grid-cols-2 gap-2 ml-4">
          <Sheet key="right">
          <SheetTrigger><img src="Menu.png" alt="menu" /></SheetTrigger>
            <SheetContent side="right">

              <Tabs defaultValue="english" className="w-full mt-8">
                <TabsList>
                  <TabsTrigger value="english" className="focus:bg-slate-500 focus:text-white">English</TabsTrigger>
                  <TabsTrigger value="spanish" className="focus:bg-slate-500 focus:text-white">Español</TabsTrigger>
                </TabsList>
                <hr className="border-t border-gray-500" />
                <TabsContent value="english">  
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Home</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-work/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Find Work</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-people/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Staffing Solutions</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/announcements2/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Resources</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/about-us/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">About Us</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/locations-list/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Locations</div>
                    </a>
                  </div>
                </TabsContent>
                <TabsContent value="spanish">  
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Inicio</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-work/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Encontrar Trabajo</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/find-people/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Soluciones</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/announcements2/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Recursos</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/about-us/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Sobre Nosotros</div>
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <a href="https://www.partnerspersonnel.com/locations-list/" className="no-underline my-4">
                      <div className="text-[#1C3F6C] text-3xl font-semibold">Ubicaciones</div>
                    </a>
                  </div>
                </TabsContent>
              </Tabs>

              
            </SheetContent>
          </Sheet>
      </div>
                </div>
            </nav>

            <div className="flex justify-center items-center w-screen md:mt-24">
                <div className="bg-white border rounded flex flex-col space-y-4 p-8 md:p-16">
                    <div className="text-2xl md:text-4xl font-bold">What are you looking for?</div>
                    <div className="text-xl md:text-2xl font-slate-500 mb-4">¿Qué estás buscando?</div>
                    <SelectableCard cardId="employee" selected={selectedCard} onClick={handleCardClick} 
                    firstLine="I'm looking for a job"
                    secondLine="Estoy buscando trabajo"
                    cardImage="maleIcon.svg" />
                    <SelectableCard cardId="company" selected={selectedCard} onClick={handleCardClick} 
                    firstLine="I'm looking to hire talent"
                    secondLine="Soy una empresa local que busca trabajadores" 
                    cardImage="Building.svg" />


                    <hr className="border-t border-gray-500 mt-4" />

                    <button className={`text-white p-2 px-4 rounded w-fit  self-end mt-4 ${buttonDisabled ? "bg-slate-200" : "bg-slate-800" }`} onClick={handleButtonClick} disabled={buttonDisabled}>Continue</button>
                </div>
            </div>
            
        </div>

    );



}; export default Preface;